import React from 'react';
import styled, { ThemeProps } from 'styled-components/macro';

import { ChatBubbleRoundIcon, ChevronDownIcon } from 'assets/icons';
import { ITheme } from 'shared/interfaces/ITheme';

interface ILauncherProps {
  isWidgetOpen: boolean;
  onToggleWidget: () => void;
}

const Launcher = ({ isWidgetOpen, onToggleWidget }: ILauncherProps): JSX.Element => {
  return (
    <LauncherContainer>
      <LauncherButton onClick={onToggleWidget}>
        <StyledChatBubbleRoundIcon $isVisible={!isWidgetOpen} />
        <StyledChevronDownIcon $isVisible={isWidgetOpen} />
      </LauncherButton>
    </LauncherContainer>
  );
};

const LauncherContainer = styled.div`
  flex: 0 0 auto;
  width: auto;
  max-width: 340px;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  position: absolute;
  right: 12px;
  bottom: 12px;
`;

interface IStyledIconProps {
  $isVisible: boolean;
}

const StyledChatBubbleRoundIcon = styled(ChatBubbleRoundIcon)<IStyledIconProps>`
  width: 38px !important;
  height: auto !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ $isVisible }): string => ($isVisible ? '1' : '0')};
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)<IStyledIconProps>`
  width: 22px !important;
  height: auto !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ $isVisible }): string => ($isVisible ? '1' : '0')};
`;

const LauncherButton = styled.button`
  width: 68px;
  height: 68px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }: ThemeProps<ITheme>): string => theme.colors.primary};
  box-shadow: 0 1px 8px 4px rgba(0, 0, 0, 0.13);
  transition: all 0.1s ease-in-out;
  position: relative;

  &:hover {
    box-shadow: 0 1px 10px 6px rgba(0, 0, 0, 0.17);
    background: ${({ theme }: ThemeProps<ITheme>): string => theme.colors.primaryDarker};
  }

  & > svg * {
    fill: ${({ theme }: ThemeProps<ITheme>): string => theme.colors.primaryContrast};
  }
`;

export default Launcher;
