const DEFAULT_COLOR_DARK = '#333';
const DEFAULT_COLOR_LIGHT = '#fff';

interface RGB {
  b: number;
  g: number;
  r: number;
}

const rgbToYIQ = ({ r, g, b }: RGB): number => {
  return (r * 299 + g * 587 + b * 114) / 1000;
};
const hexToRgb = (hex: string): RGB | undefined => {
  if (!hex || hex === undefined || hex === '') {
    return undefined;
  }
  const result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : undefined;
};
/**
 * Get the correct color contract via a hex value and threshold
 * @param colorHex
 * @param threshold
 */
export const getColorContrast = (colorHex: string | undefined, threshold = 128): string => {
  if (colorHex === undefined) {
    return DEFAULT_COLOR_DARK;
  }
  const rgb: RGB | undefined = hexToRgb(colorHex);
  if (rgb === undefined) {
    return DEFAULT_COLOR_DARK;
  }
  return rgbToYIQ(rgb) >= threshold ? DEFAULT_COLOR_DARK : DEFAULT_COLOR_LIGHT;
};
