import styled from 'styled-components/macro';

const ShadowContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.09);
  padding: 12px 16px;

  & + & {
    margin-top: 12px;
  }
`;

export default ShadowContainer;
