import React, { useEffect, useState } from 'react';
import styled, { ThemeProps } from 'styled-components/macro';
import Linkify from 'linkifyjs/react';

import { CloseIcon } from 'assets/icons';
import { ITheme } from 'shared/interfaces/ITheme';
import RequestTypeButton from 'shared/components/RequestTypeButton';
import ShadowContainer from 'shared/components/ShadowContainer';
import { ClinicWidgetRequestTypeResponse, ClinicWidgetSettingResponse } from 'shared/types';
import { Maybe } from 'shared/types/graphql';
import { GATrack } from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';

interface IWidgetPreviewProps {
  widgetSetting: ClinicWidgetSettingResponse;
  isMobile: boolean;
  onCloseClick: () => void;
  onRequestTypeClick: (type: ClinicWidgetRequestTypeResponse | null) => void;
}

const WidgetPreview = ({
  widgetSetting,
  isMobile,
  onCloseClick,
  onRequestTypeClick,
}: IWidgetPreviewProps): JSX.Element => {
  const { welcomeMessage, clinicWidgetRequestTypes, clinic } = widgetSetting;
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsDisplayed(true);
    }, 200);
  }, []);

  return (
    <WidgetPreviewContainer isDisplayed={isDisplayed} isMobile={isMobile}>
      <CloseButton onClick={onCloseClick}>
        <CloseIcon />
      </CloseButton>
      <WelcomeMessage>
        <Header>Welcome to {clinic[0].name}!</Header>
        <StyledLinkify>{welcomeMessage}</StyledLinkify>
      </WelcomeMessage>
      {clinicWidgetRequestTypes?.slice(0, 2).map((clinicWidgetRequestType) => (
        <RequestTypeButton
          key={clinicWidgetRequestType.id}
          clinicWidgetRequestType={clinicWidgetRequestType}
          onClick={onRequestTypeClick}
        />
      ))}
      {clinicWidgetRequestTypes && clinicWidgetRequestTypes.length > 2 && (
        <RequestTypeButton
          onClick={(): void => {
            GATrack(GA4Events.OTHER_OPTIONS_CLICK, { clinicId: clinic[0].id });
            onRequestTypeClick(null);
          }}
        />
      )}
    </WidgetPreviewContainer>
  );
};

interface IWidgetPreviewContainerProps {
  isDisplayed: boolean;
  isMobile: boolean;
}

interface ILinkifyProps {
  children: Maybe<string> | undefined;
}

const WidgetPreviewContainer = styled.div<IWidgetPreviewContainerProps>`
  width: 310px;
  color: ${({ theme }: ThemeProps<ITheme>): string => theme.colors.text};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: ${({ isMobile }): string => (isMobile ? '12px' : 'unset')};
  margin-bottom: ${({ isMobile }): string => (isMobile ? '92px' : '80px')};
  opacity: ${({ isDisplayed }): string => (isDisplayed ? '1' : '0')};
  transition: opacity 0.1s ease-out;
`;

const CloseButton = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeff1;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-bottom: 12px;
  transition: all 0.1s ease-in-out;

  &:hover {
    border-color: #aaa;
  }

  &:hover svg {
    transform: scale(1.08);
  }

  &:hover svg * {
    color: ${({ theme }: ThemeProps<ITheme>): string => theme.colors.text};
  }

  & svg {
    width: 12px;
    height: auto;
    transition: all 0.1s ease-in-out;
  }

  & svg * {
    fill: #363546;
  }
`;

const Header = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

const WelcomeMessage = styled(ShadowContainer)`
  padding: 16px 20px;
  line-height: 1.4;
`;

const StyledLinkify = styled(Linkify)<ILinkifyProps>`
  a {
    color: inherit;
  }
`;

export default WidgetPreview;
