import React, { useEffect, useState, useMemo, useCallback, useRef, lazy, Suspense } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { KibbleThemeProvider } from '@televet/kibble-ui/build/theme';
import * as Sentry from '@sentry/react';
import 'iframe-resizer/js/iframeResizer.contentWindow';

import {
  IWindowMessageData,
  IInitializeWidgetPayload,
  ISelectRequestTypePayload,
  ISetIsMobilePayload,
} from 'shared/interfaces/IWindowMessageData';
import { WindowMessageEvent } from 'shared/enums/WindowMessageEvent';
import { GraphQLFetchPolicy } from 'shared/enums/GraphQLFetchPolicy';
import { WidgetRequestType, useGetClinicWidgetSettingLazyQuery } from 'shared/types/graphql';
import { ITheme } from 'shared/interfaces/ITheme';
import usePrevious from 'shared/hooks/usePrevious';
import Launcher from '../Launcher';
import WidgetPreview from '../Launcher/WidgetPreview';
import Color from 'color';
import { getColorContrast } from 'shared/utils/getColorContrast';
import { ClinicWidgetRequestTypeResponse } from 'shared/types';
import { DirectBookingStep } from 'shared/enums/DirectBookingStep';
import { env } from 'env';
import { PrescriptionRefillStep } from 'shared/enums/PrescriptionRefillStep';
import useGA, { GATrack } from 'shared/hooks/useGA';
import { WidgetRequestTypeEventPrefix } from 'shared/enums/WidgetRequestTypeEventPrefix';
import { GA4Events } from 'shared/enums/GA4Events';
import { commitInfo } from 'commitInfo';

const Widget = lazy(() => import('./Widget'));
const WIDGET_CONTAINER_HEIGHT = 632;
declare global {
  interface Window {
    parentIFrame: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

export const appInfo = { appVersion: parseInt(commitInfo.commitNumber) / 100, ...commitInfo };

const Application = (): JSX.Element | null => {
  const [clinicId, setClinicId] = useState('');
  const [parentUrl, setParentUrl] = useState('');
  const [widgetDisplay, setWidgetDisplay] = useState({
    isWidgetOpen: false,
    showWidgetPreview: true,
    isMobile: false,
    isInline: false,
  });
  const applicationContainerRef = useRef<HTMLDivElement | null>(null);
  const { isWidgetOpen, showWidgetPreview, isMobile, isInline } = widgetDisplay;

  const [clinicWidgetRequestType, setClinicWidgetRequestType] = useState<ClinicWidgetRequestTypeResponse | null>(null);
  const [directBookingStep, setDirectBookingStep] = useState<DirectBookingStep>(DirectBookingStep.ContactInfo);
  const [prescriptionRefillStep, setPrescriptionRefillStep] = useState<PrescriptionRefillStep>(
    PrescriptionRefillStep.ContactInfo,
  );
  const [defaultClientPhone, setDefaultClientPhone] = useState<string>();
  const [defaultClientEmail, setDefaultClientEmail] = useState<string>();

  useGA({ clinicId });

  const [
    getClinicWidgetSettingData,
    { data: clinicWidgetSettingData, loading: isClinicWidgetSettingLoading, error: clinicWidgetSettingError },
  ] = useGetClinicWidgetSettingLazyQuery({
    fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
  });

  const integrations = useMemo(
    () => clinicWidgetSettingData?.findFirstClinicWidgetSetting?.clinic?.[0]?.integrations || [],
    [clinicWidgetSettingData?.findFirstClinicWidgetSetting?.clinic],
  );

  const widgetSetting = useMemo(() => {
    const clinicWidgetSetting = Object.assign({}, clinicWidgetSettingData?.findFirstClinicWidgetSetting);
    const requestTypes = clinicWidgetSetting?.clinicWidgetRequestTypes;
    if (clinicWidgetSetting && requestTypes?.length) {
      clinicWidgetSetting.clinicWidgetRequestTypes = requestTypes
        .filter((r) => r.isActive && !r.isDeleted)
        .sort((a, b) => a.order - b.order);
    }
    return clinicWidgetSetting;
  }, [clinicWidgetSettingData]);

  const resizeIFrame = useCallback((): void => {
    if ('parentIFrame' in window) {
      window.parentIFrame.getPageInfo((info: { clientWidth: number }) => {
        const rect = applicationContainerRef?.current?.getBoundingClientRect();
        window.parentIFrame.size(
          isWidgetOpen && isMobile ? WIDGET_CONTAINER_HEIGHT : rect?.height,
          isWidgetOpen && isMobile ? info.clientWidth : rect?.width,
        );
      });
    }
  }, [isWidgetOpen, isMobile]);

  useEffect(() => {
    if (widgetSetting && 'parentIFrame' in window) {
      resizeIFrame();
    }
  }, [widgetSetting, isWidgetOpen, isMobile, resizeIFrame]);

  useEffect(() => {
    if (clinicId) {
      try {
        getClinicWidgetSettingData({
          variables: {
            where: {
              clinic: {
                some: {
                  id: {
                    equals: clinicId,
                  },
                },
              },
            },
          },
        });
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    }
  }, [clinicId, getClinicWidgetSettingData]);

  const toggleWidget = useCallback((shouldOpen?: boolean): void => {
    setWidgetDisplay((widgetDisplay) => ({
      ...widgetDisplay,
      isWidgetOpen: typeof shouldOpen === 'boolean' ? shouldOpen : !widgetDisplay.isWidgetOpen,
      showWidgetPreview: false,
    }));
    setDirectBookingStep(DirectBookingStep.ContactInfo);
  }, []);

  const selectRequestType = useCallback(
    (type: ClinicWidgetRequestTypeResponse | null): void => {
      if (type) {
        GATrack(`${WidgetRequestTypeEventPrefix[type.requestType]}_${GA4Events.START}`, { clinicId });
      }

      if (type?.requestType === WidgetRequestType.DirectBooking) {
        setDirectBookingStep(DirectBookingStep.ContactInfo);
      }

      if (type?.requestType === WidgetRequestType.AdvancedRequestRxRefill) {
        setPrescriptionRefillStep(PrescriptionRefillStep.ContactInfo);
      }

      setClinicWidgetRequestType(type);
      setWidgetDisplay((widgetDisplay) => ({ ...widgetDisplay, isWidgetOpen: true, showWidgetPreview: false }));
    },
    [clinicId],
  );

  const [selectedRequestType, setSelectedRequestType] = useState<WidgetRequestType | null>(null);
  const previousSelectedRequestType = usePrevious(selectedRequestType);
  useEffect(() => {
    if (
      selectedRequestType &&
      (previousSelectedRequestType === null || previousSelectedRequestType === selectedRequestType) &&
      widgetSetting.clinicWidgetRequestTypes?.length
    ) {
      const selectedClinicWidgetRequestType = widgetSetting.clinicWidgetRequestTypes?.find(
        (r) => r.requestType === selectedRequestType,
      );
      if (selectedClinicWidgetRequestType) {
        selectRequestType(selectedClinicWidgetRequestType);
        setSelectedRequestType(null);
      }
    }
  }, [selectedRequestType, previousSelectedRequestType, widgetSetting, selectRequestType]);

  const handleMessage = useCallback(
    (e: MessageEvent): void => {
      const data: IWindowMessageData<IInitializeWidgetPayload & ISelectRequestTypePayload & ISetIsMobilePayload> =
        e.data;

      switch (e.data.event) {
        case WindowMessageEvent.InitializeWidget:
          if (data.payload.clinicId) setClinicId(data.payload.clinicId);
          if (data.payload.url) setParentUrl(data.payload.url);
          setWidgetDisplay((widgetDisplay) => ({
            ...widgetDisplay,
            isMobile: data.payload.hasOwnProperty('isMobile') ? data.payload.isMobile : widgetDisplay.isMobile,
            isInline: data.payload.hasOwnProperty('isInline') ? data.payload.isInline : widgetDisplay.isInline,
            isWidgetOpen: data.payload.hasOwnProperty('isOpen') ? data.payload.isOpen : widgetDisplay.isWidgetOpen,
            showWidgetPreview: data.payload.hasOwnProperty('showPreview')
              ? data.payload.showPreview
              : widgetDisplay.showWidgetPreview,
          }));
          break;
        case WindowMessageEvent.OpenWidget:
          toggleWidget(true);
          break;
        case WindowMessageEvent.CloseWidget:
          toggleWidget(false);
          break;
        case WindowMessageEvent.ToggleWidget:
          toggleWidget();
          break;
        case WindowMessageEvent.SelectRequestType:
          if (!data.payload.requestType) return;
          setSelectedRequestType(data.payload.requestType);
          break;
        case WindowMessageEvent.SetIsMobile:
          if (data.payload.hasOwnProperty('isMobile')) {
            console.log('isMobile: ' + data.payload.isMobile);
            setWidgetDisplay((widgetDisplay) => ({ ...widgetDisplay, isMobile: data.payload.isMobile }));
          }
          break;
        default:
          return;
      }
    },
    [toggleWidget],
  );

  useEffect(() => {
    window.parent.postMessage(
      {
        event: WindowMessageEvent.OnWidgetReady,
      },
      '*', // Evaluate whether this target origin is appropriate if we send sensitive data in the future
    );
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e: MessageEvent) => {
      handleMessage(e);
    });

    return (): void => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    if (!widgetSetting || !Object.keys(widgetSetting).length) return;
    const requestTypes = widgetSetting.clinicWidgetRequestTypes?.map(({ displayName, requestType, order }) => ({
      displayName,
      requestType,
      order,
    }));
    GATrack(GA4Events.IMPRESSION, {
      clinicId,
      url: parentUrl,
      requestTypes: requestTypes.map((rt) => rt.requestType).join(', '),
      isInline,
    });
    window.parent.postMessage(
      {
        event: WindowMessageEvent.OnWidgetInitialized,
        payload: { requestTypes },
      },
      '*', // Evaluate whether this target origin is appropriate if we send sensitive data in the future
    );
  }, [widgetSetting, parentUrl, clinicId, isInline]);

  const theme: ITheme = useMemo(() => {
    return {
      colors: {
        primary: widgetSetting?.primaryColor || '#554ef6',
        primaryDarker: Color(widgetSetting?.primaryColor || '#554ef6')
          .darken(0.05)
          .toString(),
        primaryContrast: getColorContrast(widgetSetting?.primaryColor || '#554ef6', 142),
        secondary: widgetSetting?.secondaryColor || '#363546',
        secondaryDarker: Color(widgetSetting?.secondaryColor || '#363546')
          .darken(0.05)
          .toString(),
        secondaryContrast: getColorContrast(widgetSetting?.secondaryColor || '#363546', 142),
        text: '#363546',
      },
      borderRadius: {
        xsmall: '0.1875rem', // 3px
        small: '0.3125rem', // 5px
        medium: '0.625rem', // 10px
        large: '1rem', // 16px
        xlarge: '1.5625rem', // 25px
      },
      boxShadow: {
        small: '0 0 3px rgba(0, 0, 0, .20)',
        large: '0 3px 20px rgba(0, 0, 0, .20)',
      },
      font: {
        fontSizes: {
          xs: '0.75rem', // 12px
          sm: '0.875rem', // 14px
          md: '1rem', // 16px
          lg: '1.125rem', // 18px
          xl: '1.25rem', // 20px
          xxl: '1.5rem', // 24px
        },
        headlineSizes: {
          h1: '2.5rem', // 40px
          h2: '2.25rem', // 36px
          h3: '2rem', // 32px
          h4: '1.5rem', // 24px
          h5: '1.25rem', // 20px
          h6: '1rem', // 16px
        },
        fontFamilies: {
          body: '"Roboto", Arial, Helvetica, sans-serif',
          heading: '"Roboto", Arial, Helvetica, sans-serif',
        },
      },
      palette: {
        primaryLightest: 'hsl(190deg 48% 70%)',
        primaryLight: 'hsl(190deg 48% 54%)',
        primary: 'hsl(190deg 48% 37%)',
        primaryDark: 'hsl(190deg 48% 25%)',
        error: '#ff3e28',
        errorLight: '#fdcab9',
        errorSecondary: '#fe5f55',
        errorSecondaryDark: '#ca2f20',
        grayWhite: '#fbfbfb',
        graySecondary: '#8f99a4',
        success: 'hsla(166, 100%, 26%, 1)',
        grayLightest: 'hsl(0,0%,90%)',
        grayLight: 'hsl(0,0%,70%)',
        grayDark: 'hsl(0,0%,40%)',
        grayDarker: 'hsl(0,0%,30%)',
        grayDarkest: 'hsl(0,0%, 20%)',
        warning: '#ff8d07',
        white: 'hsl(230,0%,98%)',
        black: 'hsl(230,68%,16%)',
      },
      spacing: {
        spacingSizes: {
          xxxs: '0.125rem', // 2px
          xxs: '0.25rem', // 4px
          xs: '0.5rem', // 8px
          sm: '0.75rem', // 12px
          md: '1rem', // 16px
          lg: '1.5rem', // 24px
          xl: '2rem', // 32px
          xxl: '2.5rem', // 40px
          xxxl: '3rem', // 48px
        },
        layoutSizes: {
          xxs: '1rem', // 16px
          xs: '1.5rem', // 24px
          sm: '2rem', // 32px
          md: '3rem', // 48px
          lg: '4rem', // 64px
          xl: '6rem', // 96px
          xxl: '10rem', // 160px
        },
      },
    };
  }, [widgetSetting]);

  const isOnHostedPreviewPage = useMemo(() => {
    if (!parentUrl) return false;
    return ['connect.televet.com', 'connect-demo.televet.com', 'connect-develop.televet.com'].includes(
      new URL(parentUrl).hostname,
    );
  }, [parentUrl]);

  const isInQaLane = useMemo(() => {
    if (!parentUrl) return false;

    const qaUrlRegex = new RegExp(`widget.(qa(\\d+)|test).dev.otto.vet`);
    return qaUrlRegex.test(new URL(parentUrl).hostname);
  }, [parentUrl]);

  const isUrlWhitelisted = useMemo(() => {
    if (!widgetSetting?.whiteLabelUrls || !parentUrl) return false;
    const isRunningLocally = env.NODE_ENV !== 'production' && new URL(parentUrl).hostname === 'localhost';
    if (isRunningLocally || isOnHostedPreviewPage || isInQaLane) {
      return true;
    }
    const whitelistUrls: string[] = widgetSetting.whiteLabelUrls;
    return whitelistUrls.some(
      (url) =>
        url === '*' ||
        new URL(url).hostname.replace(/^(www\.)/i, '') === new URL(parentUrl).hostname.replace(/^(www\.)/i, ''),
    );
  }, [widgetSetting, parentUrl, isOnHostedPreviewPage, isInQaLane]);

  useEffect(() => {
    if ('parentIFrame' in window) {
      resizeIFrame();
    }
  }, [showWidgetPreview]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { isWidgetOpen, showWidgetPreview } = widgetDisplay;
    if (showWidgetPreview && isWidgetOpen)
      setWidgetDisplay((widgetDisplay) => ({ ...widgetDisplay, showWidgetPreview: false }));
  }, [widgetDisplay]);

  if (isClinicWidgetSettingLoading || clinicWidgetSettingError || !widgetSetting?.isEnabled || !isUrlWhitelisted) {
    return null;
  }

  return (
    <ApplicationContainer
      data-iframe-height
      data-iframe-width
      isMobile={isMobile}
      isInline={isInline}
      isFullSize={isMobile || isInline}
      isWidgetOpen={isWidgetOpen}
      ref={applicationContainerRef}
    >
      <KibbleThemeProvider>
        <ThemeProvider theme={theme}>
          {isWidgetOpen && (
            // null fallback because it loads so quickly that an intermediate render looks bad
            <Suspense fallback={null}>
              <Widget
                isWidgetOpen={isWidgetOpen}
                widgetSetting={widgetSetting}
                clinicWidgetRequestType={clinicWidgetRequestType}
                parentUrl={parentUrl}
                isMobile={isMobile}
                isInline={isInline}
                onRequestTypeSelect={selectRequestType}
                setDirectBookingStep={setDirectBookingStep}
                directBookingStep={directBookingStep}
                setPrescriptionRefillStep={setPrescriptionRefillStep}
                prescriptionRefillStep={prescriptionRefillStep}
                onResizeIFrame={resizeIFrame}
                onCloseClick={toggleWidget}
                integrations={integrations}
                defaultClientPhone={defaultClientPhone}
                defaultClientEmail={defaultClientEmail}
                setDefaultClientPhone={setDefaultClientPhone}
                setDefaultClientEmail={setDefaultClientEmail}
              />
            </Suspense>
          )}
          {showWidgetPreview && (
            <WidgetPreview
              data-iframe-height
              data-iframe-width
              widgetSetting={widgetSetting}
              isMobile={isMobile}
              onRequestTypeClick={selectRequestType}
              onCloseClick={(): void => {
                GATrack(GA4Events.DISMISS_X_BUTTON, { clinicId });
                setWidgetDisplay((widgetDisplay) => ({ ...widgetDisplay, showWidgetPreview: false }));
              }}
            />
          )}
          {!(isMobile && isWidgetOpen) && !isInline && (
            <Launcher
              isWidgetOpen={isWidgetOpen}
              onToggleWidget={(): void => {
                if (isWidgetOpen) {
                  GATrack(GA4Events.DIMISS_V_ICON, { clinicId });
                } else {
                  GATrack(GA4Events.CHAT_BUBBLE_CLICK, { clinicId, isPreview: widgetDisplay.showWidgetPreview });
                }
                toggleWidget();
              }}
            />
          )}
        </ThemeProvider>
      </KibbleThemeProvider>
    </ApplicationContainer>
  );
};

interface IApplicationContainerProps {
  isMobile: boolean;
  isInline: boolean;
  isFullSize: boolean;
  isWidgetOpen: boolean;
}

const ApplicationContainer = styled.div<IApplicationContainerProps>`
  display: inline-block;
  padding: ${({ isFullSize }): string => (isFullSize ? '0' : '12px')};
  min-width: 92px;
  max-height: ${({ isInline, isFullSize }): string => (isFullSize ? '100%' : isInline ? '100vh' : 'unset')};
  min-height: 92px;
  height: ${({ isInline }): string => (isInline ? '100vh' : 'auto')};
  width: ${({ isFullSize, isWidgetOpen }): string => (isFullSize && isWidgetOpen ? '100%' : 'auto')};
  max-width: ${({ isFullSize, isWidgetOpen }): string => (isFullSize && isWidgetOpen ? '100%' : 'unset')};
`;

export default Application;
