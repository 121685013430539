import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'transparent !important',
      },
    },
  },
  colors: {
    text: {
      gray: '#363546',
      navy: '#050f5f',
    },
  },
});

export default theme;
