import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';

import 'assets/css/global.css';
import Application from 'pages/Application';
import { client } from './apolloClient';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme';

Sentry.init({
  dsn: 'https://b44db88338cb427d8d43dd225aad0753@o358860.ingest.sentry.io/5904370',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ChakraProvider theme={theme}>
      <Application />
    </ChakraProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
