declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: any;
    decode: unknown;
  }
}
type EnvType = {
  PORT: string;
  NODE_ENV: string;
  EXTEND_ESLINT: string;
  APPLICATION_URI: string;
  REACT_APP_APPLICATION_URI: string;
  REACT_APP_CORE_API_URI: string;
  REACT_APP_API_KEY: string;
  REACT_APP_PRODUCTION: string;
  REACT_APP_GA4_ID: string;
  REACT_APP_LOG_ROCKET_APP_ID: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let proc = { env: {} } as any;
if (typeof process && typeof process !== 'undefined') {
  proc = { env: process.env };
}

export const env: EnvType = { ...proc.env, ...window.env };
